@font-face {
  font-family: Proxy;
  src: url("./fonts/Proxy-mono/Proxy-Mono/Proxy-Mono/Proxy_Mono_Beta-Regular_Beta.woff");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&family=Roboto:wght@100&family=Sarpanch:wght@400;500;900&family=Titillium+Web:wght@200;300;600&display=swap");
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
  /* background: #000000; */
}

* {
  box-sizing: border-box;
}
.minting-link{
 
  color:white !important;
  margin-bottom: 2rem;
  text-decoration: none;
 
}
.status {
    font-style: normal;
    font-weight: bold;
    font-size: .8rem !important;
    margin: auto;
    letter-spacing: 2px;
  }
:root {
  --grey-desc: #bebebe;
  --background-color: #1d0909;
  --white: #f5f5f5;
  --nav-font-size: 1.1rem;
  --nav-letter-spacing: 0.2rem;
  --header-font: "Sarpanch", sans-serif;
  --header-font-weight: 500;
  --subheader-font-weight: 400;
  --roadmap_background: #000000;
  --margin: 3rem;
  --container-width: 100%;
  /* --text-shadow: #fff 0px 0px 3px, #fff 0px 0px 6px, #fff 0px 0px 9px,
    #87002b 0px 0px 12px, #87002b 0px 0px 15px, #87002b 0px 0px 30px,
    #87002b 0px 0px 40px, #87002b 0px 0px 50px; */
}
